@mixin transition($what, $time, $how) {
  -webkit-transition: $what $time $how;
  -moz-transition: $what $time $how;
  -ms-transition: $what $time $how;
  -o-transition: $what $time $how;
  transition: $what $time $how;
}

@mixin breakpoint($point) {
  @if $point == desktop {
    @media (max-width: 1399.98px) {
      @content;
    }
  } @else if $point == laptop {
    @media (max-width: 1199.98px) {
      @content;
    }
  } @else if $point == large {
    @media (max-width: 959.98px) {
      @content;
    }
  } @else if $point == medium {
    @media (max-width: 767.98px) {
      @content;
    }
  } @else if $point == small {
    @media (max-width: 575.98px) {
      @content;
    }
  }
}
