#footer {
  position: relative;
  width: 100%;
  background: $secondColor;
  padding: 16px;
  min-height: 54px;
  color: $whiteColor;

  &::before{
    content: "";
    width: 100%;
    height: 15px;
    display: block;
    background: #e2001b;
    position: absolute;
    top: 0;
    left: 0;
  }

  @include breakpoint(small){
    position: relative;
  }

  .custom{
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint(small){
      flex-direction: column-reverse;
    }
  }

  p{
    font-size: 16px;
    font-weight: 400;
    margin: 20px 0 0;
    color: $whiteColor;

    & + p{
      margin: 0;
      
      @include breakpoint(small){
        margin-top: 8px;
      }
    }

    span{
      display: inline-block;
      margin: 0 8px;
      font-size: 10px;
      vertical-align: unset;
    }
  }
  
  a {
    color: $whiteColor;

    &:hover,
    &:focus {
      color: rgba($whiteColor, 0.5);
    }
  }

}

.scrollup {
  width: 50px;
  height: 50px;
  font-size: 24px;
  line-height: 46px;
  color: #fff;
  background: #c5c6c6;
  border-radius: 50%;
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.4);
  position: fixed;
  right: 40px;
  bottom: 40px;
  display: none;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  z-index: 20;
  &:hover,
  &:focus {
    color: #fff;
    background: #777;
    text-decoration: none !important;
    outline: none;
  }

  &::before {
    font-family: $fontAwsome;
    content: "\f106";
    font-weight: 900;
  }
}