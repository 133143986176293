#visformcontainer{

  .category-desc p{
    text-align: center;
  }

  .form-flex-2cols {

    max-width: 1024px;
    margin: 0 auto 100px;

    & > fieldset{
      display: flex;
      flex-wrap: wrap;

      label.visCSSright20{
        margin-right: 10px;
      }

      label.vis_mandatory{
        width: 100%;
        padding: 0 40px;
        @include breakpoint(small) {
          padding: 0;
        }
      }

      label.visCSSlabel{
        display: none;
      }
  
      & > div{
        flex-basis: 50%;
        padding: 0 40px;
        margin: 0 0 15px;

        @include breakpoint(small) {
          flex-basis: 100%;
          margin: 0;
          padding: 0;
        }



        p.visCSSmargLeft{
          margin-left: 0;
          font-size: 15px;
          line-height: 1.5;

          a{
            color: $primaryColor;
            text-decoration: underline;
          }
        }

        input[type="checkbox"]{
          transform: scale(1.25);
          margin: 3px 0 0;
        }
        
        select,
        input[type="file"],
        input[type="text"],
        input[type="email"],
        textarea {
          float: none;
          width: 100%;
          background: transparent;
          border: 1px solid rgba($primaryColor, .2);
          padding: 0 5px;
          font-size: 17px;
          line-height: 30px;
          font-weight: normal;
          color: $blackcolor;
          &::-webkit-input-placeholder {
            font-size: 14px;
            color: rgba($blackcolor, .9);
          }
          &::-moz-placeholder {
            font-size: 14px;
            color: rgba($blackcolor, .9);
          }
          &:-ms-input-placeholder {
            font-size: 14px;
            color: rgba($blackcolor, .9);
          }
          &::-ms-input-placeholder {
            font-size: 14px;
            color: rgba($blackcolor, .9);
          }
          &::placeholder {
            font-size: 14px;
            color: rgba($blackcolor, .9);
          }

          @include breakpoint(mobile) {
            width: 100%;
          }
        }

        input[type="file"]{
          position: relative;
          font-size: 14px;
          color: rgba($blackcolor, .9);
        }
        
        input[type="file"]::file-selector-button{
          background-color: $primaryColor;
          color: $whiteColor;
          font-size: 14px;
          border: none;
          border-radius: 0;
          position: absolute;
          height: 32px;
          width: 150px;
          top: -2px;
          right: -5px;
        }
    
        select{
          border: 1px solid rgba($primaryColor, .2);
          padding: 3px 5px;
          font-size: 14px;
          height: 32px;
          width: 100%;
          
          @include breakpoint(mobile) {
            width: 100%;
          }
        }

        .visCustomText{
          margin-top: -5px;
          
          p{
            font-size: 14px;
            color:rgba($primaryColor, .5);
          }
        } 

        &.visBtnCon .btn{
          border: none;
          margin: 35px auto;
          padding: 8px 50px 12px;
          border-radius: 0px;
          position: relative;
          background-color: $primaryColor;
          color: $whiteColor;
          z-index: 1;
          font-weight: 500;
          font-size: 20px;
          @include transition(all, .3s, ease-in-out);
          &:hover{
            background-color: $secondColor;
          }
          @include breakpoint(mobile) {
            float: none;
            margin: 15px 0;
            padding: 2px 25px 4px;
          }
        }

      }

      
  
    }

    /* Field Full Width */

    &.online-bewerbung {

      div.field11,
      div.visBtnCon,
      div.captchaCont{
        flex-basis: 100%;
      }

      div.captchaCont{
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include breakpoint(small) {
          flex-wrap: wrap;
          
          /* flex-basis: 100%;
          margin: 0;
          padding: 0; */
        }

        #form1viscaptcha_response,
        #form2viscaptcha_response,
        #form3viscaptcha_response,
        #form4viscaptcha_response,
        #form5viscaptcha_response,
        #form6viscaptcha_response{
          max-width: 430px;
        }
      }
    }
  }
  
  

}

