.button {
  display: inline-block;
  padding: 8px 12px;
  background: $secondColor !important;
  color: $whiteColor !important;
  margin: 15px 0;
  position: relative;
  font-weight: 500;
  @include transition(all, 0.2s, ease-in-out);
  
  &:focus,
  &:visited{
    background: $primaryColor !important;
  }

  &:hover{
    color: $whiteColor !important;
    background: $primaryColor !important;
    outline: none;
    text-decoration: none !important;
  }

}