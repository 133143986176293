body,
html {
  font-size: 16px;
  padding: 0;
  margin: 0;
  min-height: 100vh;
}

body {
  background:fixed;
  background-size: cover;
  background-position: center;
  color: $blackcolor;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  line-height: 1.4;
  font: {
    family: $fontText;
  }
}

#loadOverlay{
  display: none;
}

a, .uk-scope a {
  outline: none;
  color: $primaryColor;
  text-decoration: none;
  
  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
    color: $blackcolor;
  }

}

h2, .uk-scope h2  {
  font-family: $fontLogo;
  font-size: 40px;
  color: $blackcolor;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 40px;
  
  @include breakpoint(mobile){
    font-size: 26px;
    margin-bottom: 25px;
  }
}

h3, .uk-scope h3{
  font-family: $fontLogo;
  font-size: 28px;
  color: $secondColor;
  font-weight: 600;
  margin-bottom: 35px;
  @include breakpoint(mobile){
    font-size: 24px;
    margin-bottom: 20px;
  }
}

h4 .uk-scope h4{
  font-size: 20px;
  font-family: $fontText;
  color: $primaryColor;
  font-weight: 600;
  @include breakpoint(mobile){
    font-size: 18px;
  }

  & + p{
    margin-top: 10px;
  }
}

h5{
  font-size: 16px;
  font-family: $fontText;

  & + p{
    margin-top: 15px;
  }
}

.content {
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
}

#wrapper {
  max-width: 1024px;
  margin: 0 auto;
  padding:100px 0 0;
}

p {
  font-size: 17px;
  color: $primaryColor;

  strong{
    font-family: $fontText;
    font-weight: 600;
  }

  & + p {
    margin-top: 15px;
  }
  & + h3 {
    margin-top: 25px;
  }
  & + h4{
    margin-top: 25px;
  }
}

ul{
  padding-left: 15px;
  list-style-type: disc;
  list-style-position: outside;
  margin-bottom: 25px;
  margin-top: 10px;
}

.p-float-end{
  float: right;
  max-width: 320px;
  margin: 20px 0 25px 30px;
  padding: 15px;
  background-color: $whiteColor;

  & + p{
    margin-top: 30px;
  }

  @include breakpoint(small){
    float: none;
    margin: 20px 0 10px;

    & + p{
      margin-top: 10px;
    }
  }
}


.min-vh-100{
  min-height: calc(100vh - 80px);
}

#main-content {
  margin: 0;
  padding: 50px 0;
  //min-height: calc(100vh - 75px);
  @include transition(all, 0.3s, ease-in-out);

  /* .active &{
    padding-left: 320px;
  } */

  @include breakpoint(tablet){
    padding-left: 0;
    .active &{
      padding-left: 0;
    }
  }

  .inhalt{
    /* margin-top: 200px;
    margin-bottom: 30px; */
    padding: 0 20px;

    .com-content-article{
      padding-top: 150px;
      min-height: calc(100vh - 54px);
      @include breakpoint(mobile) {
        padding-top: 90px;
      }
    }

    @include breakpoint(mobile) {
      padding: 0 10px;
    }

    /* ul {
      list-style: disc;
      margin: em(20) 0 em(30) em(20);
      color: $blackcolor;
    } */
  
  }
  
}

/* Contents in Modules */

#contents .mod-custom{
  padding-top: 100px;
  padding-bottom: 100px;

  &:last-child{
    border-bottom: none;
  }
}

/* #main-interaktivContent{
  max-width: 1280px;
  margin: 0 auto;

  .custom {
    padding: 50px 15px;
    position: relative;
  }

  h2{
    text-align: center;
    padding: 15px 10px;
    border-bottom: 1px solid $blackcolor;
    margin-bottom: 50px;
  }
} */