#slide {
  margin: 0 auto;

  .uk-scope ul{
    aspect-ratio: 7 / 3 !important;
    min-height: inherit !important;

    @include breakpoint(desktop){
      aspect-ratio: 6 / 3 !important;
    min-height: inherit !important;
    }
  }

  li{

    .uk-overlay{
      max-width: 1170px;
      margin: 0 auto;
      padding-top: 100px;
      text-align: right;
  
      h2{
        font-family: $fontPint;
        color: $whiteColor;
        font-size: 60px;
        line-height: 62px;
        text-shadow: 1px 1px 3px $blackcolor;
  
        strong{
          color: $secondColor;
        }
      }
    }

    &:nth-child(3) .uk-overlay{
      text-align: center;
    }

  }
  

}
