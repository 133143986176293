#donate{
    background: $bgTeaser;

    h2{
        color: $whiteColor;
        text-align: center;
        margin-bottom: 10px;
    }

    p{
        color: $whiteColor;
        text-align: center;
    }

    .button{
        font-size: 18px;
        padding:10px 30px;
    }
}