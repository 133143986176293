#main-icons{
  background: rgba($bgGrey, .1);
}

.uk-scope {
  
  .grid-icons {

    img{
      max-width: 100px;
    }

    h3{
      font-family: $fontLogo;
      font-weight: 600;
      color: $secondColor;
      margin-bottom: 40px !important;
      padding: 0 35px;
    }
  }

}

