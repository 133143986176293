.navigation {
  width: 100%;
  z-index: 4;
  overflow-y: auto;
  @include transition(all, 0.3s, ease-in-out);
  
  nav{
    ul {
      margin: 25px 0;
      padding: 0 0 0 75px;
      display: flex;
      justify-content: space-between;
      li {
        position: relative;
        a {
          font-family: $fontText;
          font-weight: 400;
          font-size: 18px;
          color: $blackcolor;
          display: block;
          padding:15px 0;
          position: relative;
          cursor: pointer;
          text-decoration: none;
          &:hover,
          &:focus {
            background: none;
            color: $secondColor;
            text-decoration: none;
            outline: none;
          }
          @include breakpoint(mobile) {
            padding: 9px 0;
            font-size: 14px;
          }
        }

        &.active a {
            color: $secondColor;
            text-decoration: none;
            outline: none;
        }


        /* &::before {
          font-family: $fontAwsome;
          content: "\f0d9";
          font-weight: 900;
          position: absolute;
          font-size: 20px;
          top: 16px;
          right: -10px;
          opacity: 0;
          color: $secondColor;
          @include transition(all, 0.5s, ease-in-out);
        } */

        &.active::before,
        &:hover::before {
          right: 0;
          opacity: 1;
        }

      }
    }
  }
  .active &{
    left: 0px;
    opacity: 1;
  }

  /* @include breakpoint(large) {
    @include MenuMobile();
  } */

  .links-footer{

    li{
      display: inline-block;
      margin: 0;
      text-align: left;
      position: relative;
      a{
        background: none;
        font-size: 13px;
        color: $primaryColor;
        font-weight: 500;
        padding: 10px 10px 0;
        text-align: center;

        &:hover{
          color: $secondColor;
        }
      }

      &:last-of-type::before{
        content: ".";
        font-size: 24px;
        position: absolute;
        left: -3px;
        top: -3px;
      }
    }
  }
}

/* Bar Menu */
.btn-menu {
  position: fixed;
  left: 30px;
  top: 45px;
  z-index: 5;
  display: none;

  @include breakpoint(medium) {
    display: block;
  }

  .bar-text{
    font-size: 18px;
    font-family: $fontText;
    font-weight: 400;
    margin-left: 15px;
    color: $primaryColor;
    display: none;

    @include breakpoint(medium) {
      display: none;
    }

    &:hover{
      color: $secondColor;
    }
  }

  .bg-fixed & {
    top: 25px;
  }
}

.bars,
.bars:before,
.bars:after {
  position: relative;
  width: 32px;
  height: 3px;
  margin: 0 auto;
  background: $secondColor;
  border-radius: 2px;
  transition: top 0.2s 0.2s, transform 0.2s;
  transform: rotate(0deg);
  display: inline-block;
  top: -5px;
}

.bars:before,
.bars:after {
  content: "";
  display: block;
  position: absolute;
}

.bars:before {
  top: -10px;
}
.bars:after {
  top: 10px;
}

.active .bars:before,
.active .bars:after {
  transition: top 0.2s, transform 0.2s 0.2s;
  top: 0;
}
.active .bars {
  background: transparent;
}
.active .bars:before {
  transform: rotate(45deg);
}
.active .bars:after {
  transform: rotate(-45deg);
}
