header {
  width: 100vw;
  height: 160px;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  text-align: left;
  z-index: 4;

  .content{
    display: block;
    /* align-items: center;
    height: 100%; */
  }

  @include breakpoint(medium){
    padding: 0;
  }


  & * {
    @include transition(all, 0.3s, ease-in-out);
  }

  .main-logo {
    text-align: center; 

    img{
      max-height: 150px;
      width: auto;
    }

    h1{
      margin: 0;
      padding: 20px;
      text-align: center;

      @include breakpoint(medium){
        //padding: 0;
      }
      
      a{
        display: inline-block
      }
    }
  }

  @include transition(all, 0.3s, ease-in-out);
  @include breakpoint(mobile) {
    padding: 0 0 0 50px;
  }


  /* .active &{
    padding-left: 320px;

    @include breakpoint(tablet){
      padding: 0 0 0 180px;
    }
    @include breakpoint(mobile) {
      padding: 0 0 0 50px;
    }
  } */

  .bg-fixed & {
    height: 100px;
    background: $whiteColor;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);

    .main-logo img{
        max-height: 80px;
        width: auto;
      }

    .main-logo h1{
      padding: 10px;
    }

  }

  
}

.header-contact-link{
  position: fixed;
  top: 50px;
  right: 0;
  background: $bgGrey;
  color: $whiteColor;
  z-index: 5;

  .bg-fixed & {
    top: 18px;
  }

  @include breakpoint(medium){
    display: none;
  }

  span{
    font-size: 20px;
    padding: 15px;
    display: block;
    cursor: pointer;
  }

}

.header-contact{
  position: fixed;
  top: 100px;
  right: -300px;
  background: $bgGrey;
  padding: 25px;
  z-index: 5;
  @include transition(all, .3s, ease-in-out);

  .bg-fixed & {
    top: 68px;
  }

  @include breakpoint(medium){
    display: none;
  }

  &.active{
    right: 0;
  }

  a, p{
    color: $whiteColor;
    font-size: 14px;
  }

  strong{
    font-weight: 400;
    font-size: 16px;
    color: $whiteColor;
  }

  a:hover{
    color: rgba($whiteColor, .85);
    cursor: pointer;
  }
}
