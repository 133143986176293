/* Fonts */
$fontText: "Nunito";
$fontPint:"Finger Paint";
$fontHeadline: 'Avenir LT Std';

$fontAwsomeBrand: "Font Awesome 5 Brands";
$fontAwsome: "Font Awesome 5 Free";
$ptIcon: 'pt-icon';

$fontLogo: $fontHeadline;

$browser-context: 16; // Default

/* Colors */

$blackcolor: #000;
$whiteColor: #fff;

$primaryColor: #575756;
$secondColor: #006bad;
$bgGrey: #706f6f;
$lightGrey: #e7e7e7;
$bgTeaser: rgba(205, 165, 88, 0.95);

$spacing: 1em;
