.fancybox-container {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
}
div.cc-window {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  border-radius: 6px 0 0 6px;
  box-shadow: 1px 1px 12px 0 rgba(0, 0, 0, 0.2),
    1px 1px 1px 0 rgba(0, 0, 0, 0.2);
}
div.cc-window.cc-right {
  right: 0;
}
div.cc-window.cc-floating {
  padding-bottom: 50px;
}
div.cc-window.cc-floating .cc-message {
  display: flex;
  flex-direction: column;
  line-height: 18px;
}
div.cc-window.cc-floating .cc-message .cc-cookie-settings-toggle {
  margin-top: 10px;
  border: 1px solid #bbb;
  border-radius: 8px;
  padding: 0.5em;
  color: #000;
  font-weight: 600;
  background: #fff;
}
div.cc-window.cc-floating .cc-message .cc-cookie-settings-toggle:hover {
  background: #bbb;
  color: #000;
}
div.cc-window.cc-floating
  .cc-message
  .cc-cookie-settings-toggle
  .cc-cookie-settings-toggler {
  display: none;
}
div.cc-window.cc-floating .cc-btn.cc-deny {
  border: 1px solid #bbb;
  border-radius: 8px;
  padding: 0.5em;
  color: #000;
  font-weight: 600;
  background: #fff;
}
div.cc-window.cc-floating .cc-btn.cc-deny:hover {
  background: #333;
  color: #fff;
  text-decoration: none;
}
div.cc-window.cc-floating .cc-btn.cc-allow,
div.cc-window.cc-floating .cc-btn.cc-allowall {
  border: 1px solid #89b400;
  border-radius: 8px;
  padding: 0.5em;
  color: #fff;
  font-weight: 600;
  background: #89b400;
}
div.cc-window.cc-floating .cc-btn.cc-allow:hover,
div.cc-window.cc-floating .cc-btn.cc-allowall:hover {
  background: #9fce05;
  border-color: #9fce05;
  text-decoration: none;
}
div.cc-window.cc-floating .cc-btn.cc-allow {
  width: 100%;
  margin-top: 10px;
}
div.cc-window.cc-floating a.cc-link label {
  font-size: 14px;
}
.cc-link.cc-cookie,
.cc-link.cc-privacy {
  position: absolute;
  width: 50%;
  text-align: center;
  bottom: 0;
}
.cc-link.cc-cookie {
  left: 0;
}
.cc-link.cc-privacy {
  right: 0;
}
.cc-header {
  max-width: inherit;
  font-size: 16px;
}
div.cc-checkbox-container {
  width: 48%;
}
input.cc-cookie-checkbox + span {
  top: 2px;
}
input.cc-cookie-checkbox + span::before {
  border: none;
}
.cc-color-override-234849139.cc-revoke {
  background-color: #89b400 !important;
}
.cc-checkboxes-container .cc-settings-label {
  padding-bottom: 10px;
  border-bottom: 1px solid #777;
  margin-bottom: 10px !important;
}
.cc-checkboxes-container div:nth-of-type(2) {
  width: 100%;
  margin-bottom: 20px;
}
.cc-checkboxes-container a:not(.cc-btn) {
  padding: 0 4px;
}
.cc-checkboxes-container a:not(.cc-btn)::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f05a";
  display: inline-block;
  margin-left: 4px;
  color: #333;
}
div.cc-cookie-category-title {
  color: #000;
}
.cc-cookie-category-description p {
  font-size: 16px;
  line-height: 22px;
  color: #000;
}

/* Update Style */
.cc-compliance {
  position: absolute;
  width: calc(100% - 40px);
  bottom: 50px;
}

div.cc-floating div.cc-checkboxes-container {
  margin-top: -5px;
  padding-bottom: 40px;
}

div.cc-window.cc-floating .cc-btn.cc-deny {
  display: none;
}

div.cc-window.cc-floating .cc-btn.cc-allow {
  margin-top: 10px;
  border: 1px solid #bbb;
  border-radius: 8px;
  padding: 0.4em;
  color: #000;
  font-weight: 600;
  background: #bbb;
  line-height: 18px;
}
div.cc-window.cc-floating .cc-btn.cc-allow:hover {
  border: 1px solid #89b400;
  background: #89b400;
  color: #fff;
}
